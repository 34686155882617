<template>
    <div>
        <label v-if="label">{{required ? label + '*' : label}}</label>
        <div>
            <v-radio-group 
              v-model="newValue"
              @change="emitValue"
              :disabled="disabled"
              :error="errors && errors.length > 0"
              :class="errors && errors.length ? 'hasError' : ''">
              <v-radio :value="o.value" v-for="(o, index) in options" :key="index" :disabled="o.disabled">
                <template v-slot:label>
                  <span v-html="o.text"></span>
                </template>
              </v-radio>
            </v-radio-group>
            <ErrorMessages :errors="errors"></ErrorMessages>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'RadioButtonGroup',
        props: {
            options: Array,
            rules: String,
            value: [Number, String, Boolean, Object],
            label: String,
            required: Boolean,
            errors: Array,
            disabled: Boolean,
        },
        emits: [
            'change'
        ],
        data() {
            return {
                newValue: null,
            }
        },
        methods: {
            emitValue() {
                this.$emit('change', this.newValue)  
            },
        },
        watch: {
            value: {
                immediate: true,
                handler(newVal) {
                    this.newValue = newVal;
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    @import '@/style.scss';

    label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
    }

</style>